import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@chakra-ui/react';
import { Field, useGet } from '@builtbypixel/nucleus';
import FormSection from '../../components/FormSection';
import useSWR from 'swr';
import { useWatch } from 'react-hook-form';
import { findAddress, getAddress } from '../../utils/postcodes.io';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { useFormContext } from 'react-hook-form';

const AddressFields = () => {
    const watchAddress = useWatch({ name: 'address' });

    const [dbAddress, setDbAddress] = useState(null);

    const { data: countries } = useSWR('/countries', useGet);

    const { setValue, getValues } = useFormContext();

    useEffect(() => {
        if (watchAddress && !dbAddress) {
            setDbAddress(watchAddress);
        } else if (dbAddress && !watchAddress) {
            setValue('address', dbAddress);
        }
    }, [watchAddress, dbAddress]);

    const loadOptions = (inputValue, callback) => {
        if (!inputValue || inputValue.length < 3) return callback([]);

        findAddress(inputValue).then((res) =>
            callback(res.data.suggestions.map((addr) => ({
                value: addr.id,
                label: addr.address,
                searchTerm: inputValue,
            }))
            )).catch((err) => {
                console.error(err);
                callback([{ label: `Error searching addresses...`, disabled: true }]);
            });
    };

    useEffect(() => {
        if (dbAddress) {
            setValue('address', dbAddress);
        }
    }, [dbAddress]);

    const onChange = async (option) => {
        if (option.disabled) return

        const { data: selectedAddr } = await getAddress(option.value).catch((err) => { console.log(err) })
        const formattedAddress = {
            address_line_one: selectedAddr?.line_1 ?? ``,
            address_line_two: selectedAddr?.line_2 ?? ``,
            address_line_three: selectedAddr?.line_3 ?? ``,
            address_city: selectedAddr?.town_or_city ?? ``,
            address_county: selectedAddr?.county ?? ``,
            address_postcode: selectedAddr?.postcode ?? ``,
            address_country: {
                id: 229,
                name: 'United Kingdom',
                code: 'GB',
                currency_name: 'British pound',
                dial_code: '+44',
                currency_symbol: '£',
                currency: 'GBP',
                created_at: null,
                updated_at: null,
            },
        };

        setDbAddress(formattedAddress);
    };

    const onChangeAddressField = (field, value) => {
        // const formattedAddress = {
        //     address_line_one: dbAddress.address_line_one,
        //     address_line_two: dbAddress.address_line_two,
        //     address_line_three: dbAddress.address_line_three,
        //     address_city: dbAddress.address_city,
        //     address_county: dbAddress.address_county,
        //     address_postcode: dbAddress?.address_postcode,
        //     address_country:  dbAddress?.address_country,
        // };
        const tmpDbAddress = Object.assign({}, dbAddress);

        tmpDbAddress[field] = value;

        setDbAddress(tmpDbAddress);
    };
    const [country, setCountry] = useState({
        code: 'GB',
        created_at: null,
        currency: 'GBP',
        currency_name: 'British pound',
        currency_symbol: '£',
        dial_code: '+44',
        id: 229,
        name: 'United Kingdom',
        updated_at: null,
    });
    useEffect(() => {
        if (!getValues('address.address_country')) {
            setValue('address.address_country', {
                id: 229,
                name: 'United Kingdom',
                code: 'GB',
                currency_name: 'British pound',
                dial_code: '+44',
                currency_symbol: '£',
                currency: 'GBP',
                created_at: null,
                updated_at: null,
            });
            setCountry({
                id: 229,
                name: 'United Kingdom',
                code: 'GB',
                currency_name: 'British pound',
                dial_code: '+44',
                currency_symbol: '£',
                currency: 'GBP',
                created_at: null,
                updated_at: null,
            });
        }
    }, [!getValues('address.address_country')]);
    return (
        <FormSection title="Address">
            <Box title="User Address">
                <AsyncSelect
                    loadOptions={debounce(
                        (inputValue, callback) => loadOptions(inputValue, callback),
                        650,
                    )}
                    placeholder="Find address by postcode..."
                    onChange={(option) => onChange(option)}
                    noOptionsMessage={({ inputValue }) =>
                        inputValue && `No option for "${inputValue}"`
                    }
                />
                <Grid templateColumns="1fr 1fr 1fr" gap="30px" mt="25px">
                    <Field
                        name="address.address_line_one"
                        value={dbAddress?.address_line_one}
                        label="Address Line 1"
                        component="text"
                        rules={{ required: true }}
                        onChange={(e) => onChangeAddressField('address_line_one', e)}
                    />
                    <Field
                        name="address.address_line_two"
                        value={dbAddress?.address_line_two}
                        label="Address Line 2"
                        component="text"
                    />
                    <Field
                        name="address.address_line_three"
                        value={dbAddress?.address_line_three}
                        label="Address Line 3"
                        component="text"
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                    <Field
                        name="address.address_city"
                        label="Address City"
                        component="text"
                        value={dbAddress?.address_city}
                        rules={{ required: true }}
                    />
                    <Field
                        name="address.address_county"
                        value={dbAddress?.address_county}
                        label="Address County"
                        component="text"
                    />

                    <Field
                        name="address.address_country"
                        component="select"
                        label="Country"
                        options={countries?.data ?? []}
                        labelKey="name"
                        valueKey="id"
                        rules={{ required: true }}
                        value={dbAddress?.address_country ?? getValues('address.address_country')}
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                    <Field
                        name="address.address_postcode"
                        label="Postcode"
                        component="text"
                        rules={{ required: true }}
                    />
                    <Field
                        name="marketing_emails"
                        label="Marketing Emails?"
                        component="switch"
                        size="lg"
                    />
                </Grid>
            </Box>
        </FormSection>
    );
};

export default AddressFields;
