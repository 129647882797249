import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { TiWarningOutline } from 'react-icons/ti';
import { Flex, Alert, AlertIcon, Text, Box, Tag, Icon, Button, Container } from '@chakra-ui/react';

function formatFieldName(fieldName) {
    return fieldName
        .split('_')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ');
}

const FieldError = ({ text, index }) =>
    <React.Fragment key={`error-${index}`}>
        <Text
            fontWeight="semibold"
            key={`fieldError-${index}`}
            mb="8px"
        >
            <Tag colorScheme="red" variant="solid" mr="10px">
                Required
            </Tag>
            {formatFieldName(text)}
        </Text>
    </React.Fragment>

const extractErrors = (errors, parentKey) =>
    Object.entries(errors).map(([key, value], index) => {
        // console.log({ key, value, index, parentKey })
        if (Object.hasOwn(value, "ref")) {
            return <FieldError text={key} index={index} key={key+index} />
        }
        return extractErrors(value, key)
    })

const InfoBar = () => {
    const { errors } = useFormState();

    const [large, setLarge] = useState(true);

    return (
        <Container
            title={large ? 'Form Errors' : null}
            maxWidth={large ? '300px' : '100px'}
            position="fixed"
            bottom="10px"
            right="10px"
            border="1px"
            borderColor="global.borderColour"
            display={Object.entries(errors).length !== 0 ? 'block' : 'none'}
            variant="card"
        >
            <Box p="10px">
                {large && (
                    <Alert status="error" rounded="5px">
                        <AlertIcon />
                        <Flex direction="column">
                            <Text pb="15px" fontSize="sm">
                                There were errors in the following fields in your form. Please
                                correct before continuing.
                            </Text>

                            {extractErrors(errors)}

                        </Flex>
                    </Alert>
                )}
                <Button
                    isFullWidth
                    mt="10px"
                    size="sm"
                    colorScheme={!large ? 'error' : 'gray'}
                    onClick={() => setLarge(!large)}
                >
                    {large ? 'Minimise' : 'View Errors'}
                </Button>
            </Box>
        </Container>
    );
};

export default InfoBar;
