import axios from 'axios';
/*
 * API reference
 * https://docs.ideal-postcodes.co.uk/api
 */

const apiKey = `jMx_LQklN02ddB6reA18eA36844`;

export const findAddress = async (queryString) => {
    const res = await axios.get(
        // `https://api.postcodes.io/postcodes/${queryString}?callback=print`
        // `https://api.getAddress.io/find/${queryString}?api-key=jMx_LQklN02ddB6reA18eA36844&expand=true  `,
        `https://api.getAddress.io/autocomplete/${queryString}?api-key=${apiKey}`,
    );

    return res;
};

export const getAddress = (addressId) =>
    axios.get(`https://api.getaddress.io/get/${addressId}?api-key=${apiKey}`);
